
import { Component, Vue } from 'vue-property-decorator'
import container from '@/components/moyo/container.vue'
import heading from '@/components/moyo/heading.vue'
import { Article } from '@/interfaces/article'

@Component({
  name: 'Blog',
  components: { container, heading },
})
export default class Blog extends Vue {
  articles: Article[]
  selectedArticle: Article
  currentPage = 1
  articlesPerPage = 8
  get articlesInPage(): Article[] {
    const start = (this.currentPage - 1) * this.articlesPerPage
    return this.articles.slice(start, start + this.articlesPerPage)
  }

  onPageChange() {
    window.scrollTo(0, 0)
  }

  data() {
    return {
      selectedArticle: undefined,
    }
  }

  created() {
    const now = new Date().getTime()
    const articlesList = [
      '2025-01-09d',
      '2025-01-09c',
      '2025-01-09b',
      '2025-01-09a',
      '2024-12-12d',
      '2024-12-12c',
      '2024-12-12b',
      '2024-12-12a',
      '2024-11-15d',
      '2024-11-15c',
      '2024-11-15b',
      '2024-11-15a',
      '2024-10-16f',
      '2024-10-16e',
      '2024-10-16d',
      '2024-10-16c',
      '2024-10-16b',
      '2024-10-16a',
      '2024-09-19e',
      '2024-09-19d',
      '2024-09-19c',
      '2024-09-19b',
      '2024-09-19a',
      '2024-08-29d',
      '2024-08-29c',
      '2024-08-29b',
      '2024-08-29a',
      '2024-07-01d',
      '2024-07-01c',
      '2024-07-01b',
      '2024-07-01a',
      '2024-03-19f',
      '2024-03-19e',
      '2024-03-19d',
      '2024-03-19c',
      '2024-03-19b',
      '2024-03-19a',
      '2024-03-08b',
      '2024-03-08a',
      '2024-02-13f',
      '2024-02-13e',
      '2024-02-13d',
      '2024-02-13c',
      '2024-02-13b',
      '2024-02-13a',
      '2024-01-09e',
      '2024-01-09d',
      '2024-01-09c',
      '2024-01-09b',
      '2024-01-09a',
      '2023-12-12d',
      '2023-12-12c',
      '2023-12-12b',
      '2023-12-12a',
      '2023-12-01',
      '2023-11-30',
      '2023-11-26',
      '2023-11-24',
      '2023-11-22',
      '2023-11-21',
      '2023-11-18',
      '2023-11-07e',
      '2023-11-07d',
      '2023-11-07c',
      '2023-11-07b',
      '2023-11-07a',
      '2023-10-26',
      '2023-10-20',
      '2023-10-13',
      '2023-10-09e',
      '2023-10-09d',
      '2023-10-09c',
      '2023-10-09b',
      '2023-10-09a',
      '2023-09-22',
      '2023-09-13',
      '2023-09-11',
      '2023-09-10f',
      '2023-09-10e',
      '2023-09-10d',
      '2023-09-10c',
      '2023-09-10b',
      '2023-09-10',
      '2023-09-09',
      '2023-09-06',
      '2023-08-31',
      '2023-08-27',
      '2023-08-25',
      '2023-08-10f',
      '2023-08-10e',
      '2023-08-10d',
      '2023-08-10c',
      '2023-08-10b',
      '2023-08-10',
      '2023-07-11',
      '2023-06-30',
      '2023-06-02',
      '2023-05-15',
      '2023-04-27',
      '2023-04-23',
      '2023-04-20',
      '2023-04-19',
      '2023-01-27',
      '2023-01-17',
      '2022-11-09',
      '2022-11-08',
      '2022-11-01',
      '2022-10-29',
      '2022-10-26',
      '2022-10-23',
    ].filter((v) => new Date(`${v.slice(0, 10)} 08:00:00`).getTime() < now)

    this.articles = articlesList.map((title) => require(`@/blog/${title}.json`))

    if (this.$route.params.article) {
      this.selectedArticle = this.articles.find(
        (v) => v.id === this.$route.params.article
      )!
    }

    this.$watch(
      () => this.$route.params.article,
      (articleId) => {
        this.selectedArticle = this.articles.find((v) => v.id === articleId)!
      }
    )
  }
}
